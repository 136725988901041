import React, { Component } from "react";
import Item from "./Item";
import Loading from "./Loading";
import { Text } from './Language';

class ProductList extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef()
  }

  /*scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Optional, um einen sanften Scroll-Effekt zu erzeugen
    });
    this.myRef?.current.scrollTo(0, this.myRef.current.offsetTop);
    window.scrollTo(0, this.myRef.current.offsetTop)
    const elemToScrollToProductTop = document.getElementById("product-top")
    elemToScrollToProductTop.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

 

  componentDidMount() {
    this.myRef?.current.scrollTo(0, this.myRef.current.offsetTop);
    window.scrollTo(0, this.myRef.current.offsetTop)
   
  }

  componentDidUpdate(prevProps) {
    if (prevProps.productWebSubCategory !== this.props.productWebSubCategory) {
      this.scrollToTop()
     

    }
    if (prevProps.productWebCategory !== this.props.productWebCategory) {
      this.scrollToTop()
    }*/
    componentDidMount() {
      this.myRef?.current.scrollTo(0, this.myRef.current.offsetTop);
      window.scrollTo(0, this.myRef.current.offsetTop)
    }
  
    componentDidUpdate(prevProps) {
      if (prevProps.productWebCategory !== this.props.productWebCategory) {
        console.log(this.props.itemSubCategoryName)
        this.myRef?.current.scrollTo(0, this.myRef.current.offsetTop);
        window.scrollTo(0, this.myRef.current.offsetTop)
        const elemToScrollToProductTop = document.getElementById("product-top")
        elemToScrollToProductTop.scrollIntoView({ behavior: 'smooth', block: 'start' })
        if (this.props.productWebCategory !== 'PickedProducts') {
          this.myRef?.current.scrollTo(0, this.myRef.current.offsetTop);
          window.scrollTo(0, this.myRef.current.offsetTop)
          elemToScrollToProductTop.scrollIntoView({ behavior: 'smooth', block: 'start' })
  
        } else {
          const elemToScrollTo = document.getElementById(this.props.productWebSubCategory)
          if (elemToScrollTo) {
            elemToScrollTo.scrollIntoView({ behavior: 'smooth', block: 'start' })
          }
        }
      }
      if (prevProps.items !== this.props.items && this.props.items) {
        this.myRef?.current.scrollTo(0, this.myRef.current.offsetTop);
        window.scrollTo(0, this.myRef.current.offsetTop)
        const elemToScrollToProductTop = document.getElementById("product-top")
        elemToScrollToProductTop.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }
  

  render() {
    if (!sessionStorage.getItem('token'))
      return (
        <div ref={this.myRef}
          className="flex  w-full items-center justify-center text-slate-900"
        >
          <div
            id="PleaseLogIn"
            className="flex flex-wrap flex-row w-full justify-center text-[30px] font-medium text-company-primary-color pb-2 "
          ><div className="w-1/2">
              <h1>
                <Text tid="PleaseLogIn" />
              </h1>
            </div>
          </div>
        </div>
      )

    if (!sessionStorage.getItem('items'))
      return (
        <div ref={this.myRef}
          className="flex w-full items-center justify-center"
        >
          <Loading />
        </div>
      )

    return (
      <>
        <div ref={this.myRef} id="product-top"
          className="flex w-full h-full"
        >
          <div
            className="flex w-full"
          >
            <div
              className="flex flex-wrap w-full justify-center"
            >
              {/* Die Produktkarten */}
              <div
                className="flex flex-wrap flex-col w-full justify-center"
              >

                {this.props.productWebCategory
                  && (this.props.productWebCategory === "Home"
                    || this.props.productWebCategory === "PickedProducts")
                  ? <div
                    className="flex flex-wrap justify-center flex-col"
                  >


                    {this.props.productWebSubCategory
                      && this.props.productWebSubCategory === "Deals"
                      ? <><div
                        id="Deals"
                        className="flex flex-wrap flex-row justify-center  text-[30px] font-medium text-company-primary-color pb-2 "
                      ><div className="w-full px-2">
                          <h1>
                            <Text tid="Sale" />
                          </h1></div>
                      </div>
                        <div className="flex justify-center">
                          <div
                            className="flex flex-wrap justify-center"
                          >
                            {
                              this.props.allCampaigns
                                ? this.props.allCampaigns.map((item, itemCode) => (
                                  <div
                                    key={itemCode}
                                    className="flex flex-wrap flex-row"
                                  >
                                    <Item
                                      value={this.props.value}
                                      handleChangeCount={this.props.handleChangeCount}
                                      item={item}
                                      onAdd={this.props.onAdd}
                                      onRemove={this.props.onRemove}
                                      onAddAllFavorites={this.props.onAddAllFavorites}
                                      onRemoveAllFavorites={this.props.onRemoveAllFavorites}
                                    />
                                  </div>

                                )
                                )
                                : null
                            }
                            {
                              this.props.allCampaigns
                                && this.props.allCampaigns.length === 0
                                ? <div
                                  className="text-slate-400  m-2"
                                >
                                  <Text tid="NoProductsInThisCategory" />
                                </div>
                                : null
                            }
                          </div></div> </>
                      : null


                    }
                    {this.props.productWebSubCategory
                      && this.props.productWebSubCategory === "New Products"
                      ? <>
                        <div
                          id="New Products"
                          className="flex flex-wrap justify-center  flex-row text-[30px] font-medium text-company-primary-color pb-2 "
                        ><div className="w-full px-2">
                            <h1>
                              <Text tid="NewProducts" />
                            </h1>
                          </div></div>
                        <div
                          className="flex flex-wrap justify-center"
                        >
                          {
                            this.props.allNewItems
                              ? this.props.allNewItems.map((item, itemCode) =>
                                <div
                                  key={itemCode}
                                  className="flex flex-wrap flex-row "
                                >
                                  <Item
                                    value={this.props.value}
                                    handleChangeCount={this.props.handleChangeCount}
                                    item={item}
                                    onAdd={this.props.onAdd}
                                    onRemove={this.props.onRemove}
                                    onAddAllFavorites={this.props.onAddAllFavorites}
                                    onRemoveAllFavorites={this.props.onRemoveAllFavorites}
                                  />
                                </div>
                              )
                              : null
                          }
                          {
                            this.props.allNewItems
                              && this.props.allNewItems.length === 0
                              ? <div className="text-slate-400  m-2">
                                <Text tid="NoProductsInThisCategory" />
                              </div>
                              : null
                          }
                        </div>
                      </>
                      : null


                    }
                    {this.props.productWebSubCategory
                      && this.props.productWebSubCategory === "Favorites"
                      ? <>
                        <div
                          id="Favorites"
                          className="flex flex-wrap flex-row justify-center text-[30px] font-medium text-company-primary-color pb-2 "
                        ><div className="w-full px-2">
                            <h1>
                              <Text tid="Favorites" />
                            </h1>
                          </div></div>
                        <div
                          className="flex flex-wrap justify-center"
                        >
                          {
                            this.props.allFavorites
                              ? this.props.allFavorites.map((item, itemCode) =>
                                <div
                                  key={itemCode}
                                  className="flex flex-wrap flex-row "
                                >
                                  <Item
                                    value={this.props.value}
                                    handleChangeCount={this.props.handleChangeCount}
                                    item={item}
                                    onAdd={this.props.onAdd}
                                    onRemove={this.props.onRemove}
                                    onAddAllFavorites={this.props.onAddAllFavorites}
                                    onRemoveAllFavorites={this.props.onRemoveAllFavorites}
                                  />
                                </div>
                              )
                              : null
                          }
                          {
                            this.props.allFavorites
                              && this.props.allFavorites.length === 0
                              ? <div
                                className="text-slate-400  m-2"
                              >
                                <Text tid="NoProductsInThisCategory" />
                              </div>
                              : null
                          }
                        </div>
                      </>
                      : null


                    }
                    {this.props.productWebSubCategory
                      && this.props.productWebSubCategory === "Your Choice"
                      ? <>
                        <div
                          id="Your Choice"
                          className="flex flex-wrap flex-row justify-center text-[30px] font-medium text-company-primary-color pb-2 "
                        ><div className="w-full px-2">
                            <h1>
                              <Text tid="YourChoice" />
                            </h1>
                          </div>
                        </div>
                        <div
                          className="flex flex-wrap justify-center"
                        >
                          {
                            this.props.allSold
                              ? this.props.allSold.map((item, itemCode) =>
                                <div
                                  key={itemCode}
                                  className="flex flex-wrap flex-row"
                                >
                                  <Item
                                    value={this.props.value}
                                    handleChangeCount={this.props.handleChangeCount}
                                    item={item}
                                    onAdd={this.props.onAdd}
                                    onRemove={this.props.onRemove}
                                    onAddAllFavorites={this.props.onAddAllFavorites}
                                    onRemoveAllFavorites={this.props.onRemoveAllFavorites}
                                  />
                                </div>
                              )
                              : null
                          }
                          {
                            this.props.allSold
                              && this.props.allSold.length === 0
                              ? <div
                                className="text-slate-400 m-2"
                              >
                                <Text tid="NoProductsInThisCategory" />
                              </div>
                              : null
                          }
                        </div>
                      </>
                      : null


                    }
                    {this.props.productWebSubCategory
                      && this.props.productWebSubCategory === "Discover"
                      ? <>
                        <div
                          id="Discover"
                          className="flex flex-wrap flex-row justify-center text-[30px] font-medium text-company-primary-color pb-2 "
                        ><div className="w-full px-2">
                            <h1>
                              <Text tid="Discover" />
                            </h1>
                          </div></div>
                        <div
                          className="flex flex-wrap justify-center"
                        >
                          {
                            this.props.notSold
                              ? this.props.notSold.map((item, itemCode) =>
                                <div
                                  key={itemCode}
                                  className="flex flex-wrap flex-row"
                                >
                                  <Item
                                    value={this.props.value}
                                    handleChangeCount={this.props.handleChangeCount}
                                    item={item}
                                    onAdd={this.props.onAdd}
                                    onRemove={this.props.onRemove}
                                    onAddAllFavorites={this.props.onAddAllFavorites}
                                    onRemoveAllFavorites={this.props.onRemoveAllFavorites}
                                  />
                                </div>
                              )
                              : null
                          }
                          {
                            this.props.notSold
                              && this.props.notSold.length === 0
                              ? <div
                                className="text-slate-400 m-2"
                              >
                                <Text tid="NoProductsInThisCategory" />
                              </div>
                              : null
                          }
                        </div>
                      </>
                      : null


                    }

                  </div>

                  : null
                }




                {
                  this.props.itemSubCategoryName && !this.props.filteredData
                    ? this.props.itemSubCategoryName.map((itemSubGrpCode, index) =>
                      <div
                        key={index}
                        className="flex flex-wrap w-full flex-col p-0 justify-center"
                      >
                        <div
                          id={itemSubGrpCode}
                          className="flex flex-row text-[30px] font-medium text-company-primary-color justify-center"
                        >
                          <div
                            id="content-top"
                          >
                          </div>
                          {
                            this.props.items
                            && this.props.items?.find(x =>
                              x.itemSubCategoryName === itemSubGrpCode
                              && x.itemCategoryName === this.props.productWebCategory
                            )
                            && <div className="w-full px-2 mb-2"><h1>{itemSubGrpCode}</h1></div>
                          }
                        </div>
                        <div
                          className="flex w-full flex-wrap flex-row justify-center"
                        >
                          {
                            this.props.items
                              ? this.props.items?.map((item, itemCode) =>
                              (
                                item.itemCategoryName === this.props.productWebCategory
                                  && itemSubGrpCode === item.itemSubCategoryName
                                  ? <div
                                    key={itemCode}
                                    className="flex flex-wrap flex-col"
                                  >
                                    <Item
                                      value={this.props.value}
                                      handleChangeCount={this.props.handleChangeCount}
                                      item={item}
                                      onAdd={this.props.onAdd}
                                      onRemove={this.props.onRemove}
                                      onAddAllFavorites={this.props.onAddAllFavorites}
                                      onRemoveAllFavorites={this.props.onRemoveAllFavorites}
                                    />
                                  </div> : null
                              )
                              ) : null
                          }
                        </div>
                      </div>
                    ) : null
                }
                {
                  this.props.filteredData
                  && <div className="flex flex-wrap mt-3 justify-center" id="content-top">
                    {
                      this.props.filteredData?.map((item, itemCode) =>
                      (
                        <div
                          key={itemCode}
                          className=""
                        >
                          <Item
                            value={this.props.value}
                            handleChangeCount={this.props.handleChangeCount}
                            item={item}
                            onAdd={this.props.onAdd}
                            onRemove={this.props.onRemove}
                            onAddAllFavorites={this.props.onAddAllFavorites}
                            onRemoveAllFavorites={this.props.onRemoveAllFavorites}
                          />
                        </div>
                      )
                      )
                    }

                  </div>
                }
              </div>

            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ProductList;