import React, { useState, useContext } from "react";
import { LockClosedIcon } from '@heroicons/react/solid'
import LanguageSelector from './LanguageSelector';
import GetCustomerCode from "./GetCustomerCode";
import LoginToWebshop from "./components/CustomHooks/LoginToWebshop";
import { Text, LanguageContext } from './Language';
import { ReactComponent as LogoutIcon } from './assets/pic/logout.svg';
import logo from './assets/pic/logo.png';
import { VERSION } from './components/Constants';
import fetchItemsFromAPI from "./fetchItemsFromAPI";
import fetchDataFromAPI from "./useFetchDataFromAPI";

//import ReactDOM from "react-dom";
//import PropTypes from 'prop-types';

function setToken() {
}

function getToken() {
    const tokenString = sessionStorage.getItem('token');
    return tokenString;
}

export default function LoginModal({onLogoutDeleteItemsAndCategories, onGetCategories, onGetItems, parentHandleToken, children }) {
    const [customerCode, setCustomerCode] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showModal, setShowModal] = React.useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const languageContext = useContext(LanguageContext);

    var token = getToken();


    function handleLogout() {
        window.location.reload();
        setCustomerCode("")
        setEmail("")
        setPassword("")
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('discRate');
        sessionStorage.removeItem('slipRef');
        sessionStorage.removeItem('items');
        sessionStorage.removeItem('allCategories');
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('email');
        sessionStorage.removeItem('customerCode');
        sessionStorage.removeItem('Expl1');
        sessionStorage.removeItem('Expl2');
        //localStorage.clear();
        setToken("");
        setIsSubmitted(false);
        parentHandleToken(false);
        onLogoutDeleteItemsAndCategories()
    }

    const handleSubmit = async event => {
        event.preventDefault();
        sessionStorage.setItem('email', email)
        sessionStorage.setItem('customerCode', customerCode)
        LoginToWebshop(customerCode, email, password)
            .then((json) => {
                if (json.resCode === 200) {
                    fetchDataFromAPI(1306) //service for categories
                        .then(() => { 
                            console.log("fetch category when login")
                            onGetCategories()
                            fetchItemsFromAPI(json.dataSet[0]?.token) //service for GetBasket AND! service for Items.Get.Customer.Catalog
                                .then(() => {
                                    console.log("fetch items when login")
                                    onGetItems()
                                })
                        })
                }
                token = getToken();
                if (token) {
                    setIsSubmitted(true);
                    parentHandleToken(true);
                } else {
                }
            })
    };

    const renderForm = (
        <>
            <button
                className="flex w-28 h-10 m-1 items-center 
                justify-center  p-3 
                text-sm font-medium text-white hover:opacity-75"
                type="button"
                onClick={() => setShowModal(true)}
            >
                {children ? children :
                    <Text tid="Login" />}
            </button>
            {showModal ? (
                <>
                    <div
                        className="fade In justify-center items-center flex overflow-x-hidden 
                        overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none
                        bg-white"
                    >
                        <div
                            className="relative w-auto my-6 mx-auto max-w-3xl text-slate-900"
                        >
                            {/*content*/}

                            <div
                                className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-gradient-to-r from-company-dark-color to-company-primary-color outline-none focus:outline-none"
                            >
                                {/*header*/}
                                <div
                                    className="flex items-center justify-between p-5 border-b border-solid border-transparent rounded-t"
                                >
                                    <h3
                                        className="text-lg w-24 font-semibold text-white"
                                    >
                                        <Text tid="SignIn" />
                                    </h3>
                                    <div className="flex w-full mr-5 justify-end items-center">
                                        <LanguageSelector />
                                    </div>
                                    <button
                                        className="flex items-center justify-center ml-auto border-0 text-black opacity-50 float-right text-xl 
                                        leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span
                                            className="bg-transparent h-7 w-7 text-xl inline-block align-text-top outline-none focus:outline-none"
                                        >
                                            &#10006;
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div
                                    className="relative flex-auto"
                                >
                                    <div
                                        className="mb-4 mr-4 text-slate-500 text-lg leading-relaxed">
                                        { /*----------------------------------------------------------------------------*/}

                                        <div
                                            className="h-56 flex flex-row items-center justify-center py-1 px-1 sm:px-1 lg:px-1"
                                        >
                                            <div className="flex flex-col h-full justify-center">
                                                <div className="flex w-96 h-56 justify-center items-center">
                                                    <img className="w-80 p-2" src={logo} alt="Logo" />
                                                </div>
                                                <div className='bg-transparent flex p-2 justify-center text-slate-300'>
                                                    <p className='text-very-small'>
                                                        {VERSION}
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                className="flex flex-col self-center max-w-md w-full space-y-2 justify-center"
                                            >

                                                <form
                                                    className="mt-1 space-y-6 "
                                                    action="#"
                                                    method="POST"
                                                    onSubmit={handleSubmit}>
                                                    <input
                                                        type="hidden"
                                                        name="remember"
                                                        defaultValue="true"
                                                    />
                                                    <div
                                                        className="-space-y-px"
                                                    >
                                                        <div>
                                                            <label
                                                                id="labelCustomerCode"
                                                                htmlFor="customerCode"
                                                                className="sr-only"
                                                            >
                                                                <Text tid="CustomerCode" />
                                                            </label>
                                                            <div
                                                                className=" border-white focus:outline-none focus:ring-company-primary-color focus:outline-none
                                                                focus:border-company-primary-color focus:z-10 focus:border-r focus:border-t
                                                                 border-t-4 border-r-2 flex flex-row items-center"
                                                            >
                                                                <input
                                                                    onChange={(e) => setCustomerCode(e.target.value)}
                                                                    value={customerCode}
                                                                    id="customerCode"
                                                                    name="customerCode"
                                                                    type="text"
                                                                    autoComplete="customerCode"
                                                                    required
                                                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border-none  
                                                                    bg-transparent placeholder-white text-white   sm:text-sm
                                                                    focus:ring-company-primary-color-100 focus:outline-none
                                                                focus:border-company-primary-color focus:z-10"
                                                                    placeholder={languageContext.dictionary["CustomerCode"]}
                                                                />
                                                                <GetCustomerCode
                                                                    email={email}
                                                                    password={password}
                                                                    getCustomerCode={(code) => setCustomerCode(code)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <label
                                                                htmlFor="email-address" className="sr-only"
                                                            >
                                                                <Text tid="EmailAddress" />
                                                            </label>
                                                            <input
                                                                onChange={(e) => setEmail(e.target.value)}
                                                                id="email-address"
                                                                name="email"
                                                                type="email"
                                                                autoComplete="email"
                                                                required
                                                                className="appearance-none rounded-none relative block w-full px-3 py-2 border-r-2 
                                                                bg-transparent border-white placeholder-white text-white  focus:outline-none focus:ring-company-primary-color-100 
                                                                focus:border-white focus:border-r focus:z-10 sm:text-sm border-l-0"
                                                                placeholder={languageContext.dictionary["EmailAddress"]}
                                                            />
                                                        </div>
                                                        <div>
                                                            <label htmlFor="password" className="sr-only">
                                                                <Text tid="Password" />
                                                            </label>
                                                            <input
                                                                onChange={(e) => setPassword(e.target.value)}
                                                                id="pass"
                                                                name="pass"
                                                                type="password"
                                                                autoComplete="current-password"
                                                                required
                                                                className="border-white focus:outline-none focus:ring-company-primary-color-100  focus:outline-none
                                                                focus:border-white focus:z-10 focus:border-r focus:border-b border-l-0
                                                                 border-b-4 border-r-2 appearance-none rounded-none relative block w-full px-3 py-2  
                                                                bg-transparent placeholder-white text-white   sm:text-sm"
                                                                placeholder={languageContext.dictionary["Password"]}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <button
                                                            type="submit"
                                                            className="border-b-4 border-r-2 border-t-4 border-white group relative w-full flex justify-center
                                                            font-medium bg-transparent hover:bg-company-primary-color focus:outline-none focus:ring-2                                                             
                                                            focus:ring-offset-2 text-white focus:ring-company-primary-color items-center px-1 py-1 text-base shadow-sm hover:opacity-75"                                                        >
                                                            <span
                                                                className="absolute left-0 inset-y-0 flex items-center pl-3"
                                                            >
                                                                <LockClosedIcon
                                                                    className="h-5 w-5 text-white group-hover:text-blue-100"
                                                                    aria-hidden="true" />
                                                            </span>
                                                            <Text tid="SignIn" />
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>

                                        </div>
                                        {/*-----------------------------------------------------------------------------------------*/}
                                    </div>
                                </div>
                                {/*footer*/}

                            </div>
                        </div>
                    </div>
                    <div
                        className="opacity-50 fixed inset-0 z-40 bg-black"
                    >
                    </div>
                </>
            ) : null}
        </>
    );

    const renderLogout = (
        <button
            className="flex w-28 lg:w-32 items-center 
            rounded-md border border-transparent
            text-base font-medium text-company-primary-color shadow-sm hover:opacity-75"
            type="button"
            onClick={() => handleLogout()}
        >
            <LogoutIcon className="w-6 h-6 fill-company-primary-color mr-2" />
            <Text tid="SignOff" />
        </button>
    );

    return (
        <div>
            {
                isSubmitted
                    ? renderLogout
                    : sessionStorage.getItem('token')
                        ? renderLogout
                        : renderForm
            }
        </div>
    );
}

